<template>
  <div class="container-main">
    <div class="abt-us">
      <p>
        এই সাইটটি মোম্যাজিক বাংলাদেশ লিমিটেড এর একক মালিকানাধীন প্রপার্টি।
        যেকোনো অদল বদল অথবা বিক্রয় এর ক্ষমতা প্রতিষ্ঠানটি সংরক্ষণ করে।
      </p>
      <p>
        <label class="lblbold">আপনার কিছু উল্লেখযোগ্য অধিকারঃ</label><br />
        আমাদের কাছে আপনার প্রদত্ত সকল তথ্য আপনি পুনরুদ্ধার করতে পারেন, পরিবর্তন
        করতে পারেন অথবা পুরোপুরি মুছে ফেলতে পারেন।<br />
        আমরা আপনাকে কখনই অপ্রয়োজনীয় কোনো ইমেইল পাঠাব না।<br />
        আপনার দেওয়া ব্যক্তিগত তথ্যগুলো শুধুমাত্র হুল্লোড় ও এর সহযোগী
        প্রতিষ্ঠানসমূহ ব্যবহার করবে।
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermCondition"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-main {
  display: block;
  margin: 0 auto;
}

.title-vdo-01 {
  padding: 15px 15px 10px 15px;
  font-family: inherit;
  font-weight: 600;
  font-size: 24px;
  color: aliceblue;
  line-height: 1.2;
  /* background: #232f3e; */
}

.abt-us {
  margin: 5px 15px 25px 15px;
  display: block;
  padding-bottom: 40px;
}

.abt-us p {
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  color: aliceblue;
  line-height: 2;
  margin-bottom: 20px;
}
</style>
